<script setup lang="ts">
const { $auth } = useNuxtApp()
const router = useRouter()

const logout = async () => {
  await $auth.logout()
  router.push('/')
}
</script>

<template>
  <transition
    name="fade"
    mode="in-out"
    enter-from-class="opacity-0"
    enter-active-class="transition-opacity duration-200"
    leave-active-class="transition-opacity duration-500"
    leave-to-class="opacity-0"
  >
    <div
      class="absolute right-0 z-10 flex flex-col whitespace-nowrap bg-s-50 text-xs font-semibold shadow-contextual-subnavigation"
    >
      <LinkOrAnchor to="/account" class="block px-4 py-3 hover:bg-p-200">
        Account Details
      </LinkOrAnchor>
      <button class="block px-4 py-3 text-left hover:bg-p-200" @click="logout">
        Log Out
      </button>
    </div>
  </transition>
</template>

<script setup lang="ts">
import IconMenu from '@/assets/icons/menu.svg'
import IconClose from '@/assets/icons/close.svg'
import IconSearch from '@/assets/icons/search.svg'
import IconBenchmarkLogo from '@/assets/icons/benchmark-logo.svg'
import { useAuthStore } from '@/stores/auth'

const authStore = useAuthStore()
const { $modal } = useNuxtApp()
</script>

<template>
  <header class="bg-white">
    <div
      class="mx-auto flex h-[45px] max-w-content-container items-center justify-between"
    >
      <!-- Left -->
      <div class="flex w-1/4 gap-4 pl-5">
        <button
          data-testid="mobile-burger"
          @click="$modal.toggle('mobileMenu')"
        >
          <IconClose v-if="$modal.mobileMenu" />
          <IconMenu v-else />
        </button>
        <LinkOrAnchor to="/search">
          <IconSearch />
        </LinkOrAnchor>
      </div>

      <!-- Center -->
      <LinkOrAnchor
        class="flex w-1/2 flex-col items-center justify-center text-center"
        to="/"
      >
        <IconBenchmarkLogo class="w-40" />
      </LinkOrAnchor>

      <!-- Right -->
      <div class="flex h-full w-1/4 items-center justify-end gap-4">
        <ClientOnly>
          <div v-if="authStore.token !== undefined" class="h-full">
            <a
              v-if="!authStore.isAuthenticated"
              href="/login"
              class="flex h-full items-center justify-center bg-p-100 px-5 text-xs font-semibold"
              data-testid="mobile-login-link"
              @click.prevent="$modal.open('login')"
            >
              Login
            </a>
            <div
              v-if="authStore.isAuthenticated"
              class="relative h-full"
              @mouseleave="$modal.close('accountMenu')"
            >
              <button
                class="h-full px-5 text-xs font-semibold"
                @click="$modal.toggle('accountMenu')"
              >
                Account
              </button>
              <AccountMenu v-if="$modal.accountMenu" />
            </div>
          </div>
        </ClientOnly>
      </div>
    </div>
  </header>
</template>

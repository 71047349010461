<script setup lang="ts">
import HSForm from '~/components/forms/HSForm.vue'
import RefineInterestsForm from '~/components/forms/contact/RefineInterestsForm.vue'

withDefaults(
  defineProps<{
    withCloseButton?: boolean
  }>(),
  {
    withCloseButton: true,
  },
)

defineEmits(['close'])

const config = useRuntimeConfig()
const formId = config.public.hubspotLearnMoreProductsFormId as string
</script>

<template>
  <HSForm
    class="learn-more-products h-full"
    :form-id="formId"
    :with-close-button="withCloseButton"
    @close="$emit('close')"
  >
    <template #header>
      <slot name="header" />
      <h5 class="text-lg font-semibold">Learn more about our products</h5>
      <p class="mt-3 mb-1 ml-auto w-fit text-xxs">
        Fields marked <span class="text-error">*</span> are required
      </p>
    </template>

    <template #success="{ closeForm }">
      <RefineInterestsForm
        :with-close-button="withCloseButton"
        @close="closeForm"
      />
    </template>
  </HSForm>
</template>

<style>
.learn-more-products .bm-custom-form .hs-submit {
  margin-top: auto;
}
</style>

import payload_plugin_rHviMFj3vW from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.10.1_pinia@3.0.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_oBumqDSH1L from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.5_eslint@9.23.0_sass@1.86.1_stylelint@16.17.0_typescript@5.7.3_vite@6.2.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_adY7EfyQb4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.5_eslint@9.23.0_sass@1.86.1_stylelint@16.17.0_typescript@5.7.3_vite@6.2.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZfXbMnvyBo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.5_eslint@9.23.0_sass@1.86.1_stylelint@16.17.0_typescript@5.7.3_vite@6.2.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_nx9n1imsvP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.5_eslint@9.23.0_sass@1.86.1_stylelint@16.17.0_typescript@5.7.3_vite@6.2.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_YUvJBFteYb from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.5_eslint@9.23.0_sass@1.86.1_stylelint@16.17.0_typescript@5.7.3_vite@6.2.4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3x5nXC63ds from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.5_eslint@9.23.0_sass@1.86.1_stylelint@16.17.0_typescript@5.7.3_vite@6.2.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kcZHkcLvh6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.5_eslint@9.23.0_sass@1.86.1_stylelint@16.17.0_typescript@5.7.3_vite@6.2.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ScBjjXOyot from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.10.1_pinia@3.0.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_d5jt4yWbCt from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.5_eslint@9.23.0_sass@1.86.1_stylelint@16.17.0_typescript@5.7.3_vite@6.2.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _0_catch_errors_and_exceptions_client_jEVKhqGlRS from "/opt/buildhome/repo/plugins/0.catch-errors-and-exceptions.client.ts";
import cloudinary_SEKWrTzwvn from "/opt/buildhome/repo/plugins/cloudinary.ts";
import content_CsGsOPNIRB from "/opt/buildhome/repo/plugins/content.ts";
import cookies_rCS2psPm8j from "/opt/buildhome/repo/plugins/cookies.ts";
import dummy_model_ItnpozlIRX from "/opt/buildhome/repo/plugins/dummy-model.ts";
import google_analytics_client_KruZkwwzId from "/opt/buildhome/repo/plugins/google-analytics.client.ts";
import hubspot_identity_client_uKz9KByQo6 from "/opt/buildhome/repo/plugins/hubspot-identity.client.ts";
import modal_UEFnUXzgTX from "/opt/buildhome/repo/plugins/modal.ts";
import scroll_reset_M4VQ40sLHo from "/opt/buildhome/repo/plugins/scroll-reset.ts";
import sentry_client_config_o5jjUx2pcO from "/opt/buildhome/repo/plugins/sentry.client.config.ts";
import seo_manager_WfCGakEskd from "/opt/buildhome/repo/plugins/seo-manager.ts";
import theme_zupea9J243 from "/opt/buildhome/repo/plugins/theme.ts";
import token_NCWuir2H2e from "/opt/buildhome/repo/plugins/token.ts";
import vee_validate_KcKlKmvCrJ from "/opt/buildhome/repo/plugins/vee-validate.ts";
import vimeo_client_BFAm9LGErL from "/opt/buildhome/repo/plugins/vimeo.client.ts";
import y_rest_client_ouPNQxsruT from "/opt/buildhome/repo/plugins/y.rest-client.ts";
import ya_membership_oqaQS7MApt from "/opt/buildhome/repo/plugins/ya.membership.ts";
import z_apollo_kElVZx10q2 from "/opt/buildhome/repo/plugins/z.apollo.ts";
import z_app_version_watcher_cqHyqBLNWq from "/opt/buildhome/repo/plugins/z.app-version-watcher.ts";
import z_auth_BqBYggCFPK from "/opt/buildhome/repo/plugins/z.auth.ts";
import z_graphql_client_5cZ6csbNhp from "/opt/buildhome/repo/plugins/z.graphql-client.ts";
export default [
  payload_plugin_rHviMFj3vW,
  revive_payload_client_oBumqDSH1L,
  unhead_adY7EfyQb4,
  router_ZfXbMnvyBo,
  payload_client_nx9n1imsvP,
  navigation_repaint_client_YUvJBFteYb,
  check_outdated_build_client_3x5nXC63ds,
  chunk_reload_client_kcZHkcLvh6,
  plugin_vue3_ScBjjXOyot,
  components_plugin_KR1HBZs4kY,
  prefetch_client_d5jt4yWbCt,
  _0_catch_errors_and_exceptions_client_jEVKhqGlRS,
  cloudinary_SEKWrTzwvn,
  content_CsGsOPNIRB,
  cookies_rCS2psPm8j,
  dummy_model_ItnpozlIRX,
  google_analytics_client_KruZkwwzId,
  hubspot_identity_client_uKz9KByQo6,
  modal_UEFnUXzgTX,
  scroll_reset_M4VQ40sLHo,
  sentry_client_config_o5jjUx2pcO,
  seo_manager_WfCGakEskd,
  theme_zupea9J243,
  token_NCWuir2H2e,
  vee_validate_KcKlKmvCrJ,
  vimeo_client_BFAm9LGErL,
  y_rest_client_ouPNQxsruT,
  ya_membership_oqaQS7MApt,
  z_apollo_kElVZx10q2,
  z_app_version_watcher_cqHyqBLNWq,
  z_auth_BqBYggCFPK,
  z_graphql_client_5cZ6csbNhp
]
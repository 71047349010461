import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
} from '@apollo/client/core'
import merge from 'lodash/merge.js'
import { useAuthStore } from '@/stores/auth'
import { ApolloClientProxy } from '@/utils/http'

export default defineNuxtPlugin(({ $config, $token }) => {
  const noCachingOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  /**
   * This client is meant to be used with queries
   * that don't require authorization, such as archive
   * pages
   */
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: noCachingOptions,
    uri: $config.public.publicGraphqlEndpoint,
    headers: { 'X-Source': 'Source' },
  })

  /**
   * Link that attach authorization headers if a token
   * exists
   */
  const httpLink = createHttpLink({
    uri: $config.public.graphqlEndpoint,
    fetch: async (uri, options) => {
      const authHeaders = await $token.getAuthorizationHeader()
      options = await merge(options, authHeaders)
      options.headers['X-Source'] = 'Source'
      return fetch(uri, options)
    },
  })

  /**
   * This client is for queries that require authorization,
   * such as Membership article detail page
   */
  const privateApolloClient: ApolloClientProxy = new ApolloClientProxy(
    new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache(),
      defaultOptions: noCachingOptions,
    }),
    $token,
    { maxNumberOfRetries: 1 },
  )

  const getGraphqlClient = () => {
    return useAuthStore().isAuthenticated || $config.public.isPreviewSite
      ? privateApolloClient
      : apolloClient
  }

  return {
    provide: {
      apollo: {
        publicClient: apolloClient,
        privateClient: privateApolloClient,
        getClient: getGraphqlClient,
      },
    },
  }
})

<template>
  <TheLoader v-if="loading" size="xlarge" :class="$attrs.class" />
  <div
    v-show="!loading"
    class="relative flex flex-col w-full"
    :class="[
      $attrs.class,
      {
        '!overflow-hidden': sendLoading,
      },
    ]"
  >
    <slot v-if="sendLoading" name="sendLoading">
      <div
        class="absolute inset-0 size-full bg-white/90 backdrop-blur flex flex-col gap-y-5 items-center justify-center font-semibold"
      >
        <TheLoader class="h-fit" size="xlarge" />
        <span>Sending</span>
      </div>
    </slot>

    <template v-if="!showSuccess">
      <slot name="header" />

      <div :id="containerId" ref="formRef" class="bm-custom-form" />

      <slot name="footer">
        <NuxtLink
          to="/privacy"
          target="_blank"
          class="text-s-900 underline text-xs text-center mt-2"
          no-prefetch
        >
          Privacy Policy
        </NuxtLink>
      </slot>
    </template>
    <slot
      v-else
      name="success"
      :refresh-form="refreshForm"
      :close-form="closeForm"
    >
      <DefaultSuccessMessage
        :message="props.successMessage"
        :with-close-button="withCloseButton"
        @close="closeForm"
      />
    </slot>
  </div>
</template>

<script setup lang="ts">
import TheLoader from '~/components/TheLoader.vue'
import DefaultSuccessMessage from '~/components/forms/DefaultSuccessMessage.vue'

import { useAuthStore } from '~/stores/auth'
import type { TCommon } from '~/types/app.type'

type TProps = {
  formId: string
  portalId?: string
  region?: string
  withCloseButton?: boolean
  successMessage?: string
  formType?: string
  formName?: string
}

type TBeforeSubmit = {
  form: HTMLFormElement
  ovalues: TCommon
}

type TSubmitted = {
  form: HTMLFormElement
  values: TCommon
  refreshForm: () => void
}

const config = useRuntimeConfig()
const authStore = useAuthStore()

const userEmail = computed(() => authStore.userInfo?.email || '')

const props = withDefaults(defineProps<TProps>(), {
  withCloseButton: true,
  formType: 'contact-form',
  formName: 'Contact Us',
})

const emit = defineEmits<{
  'on-submitted': [args: TSubmitted]
  'on-before-init': []
  'on-ready': [form: HTMLFormElement]
  'on-before-submit': [args: TBeforeSubmit]
  close: []
}>()

const formRef = ref<HTMLDivElement | null>(null)
const containerId = useId()
const formInstanceId = `hs-form-${containerId}`
const loading = ref(true)
const sendLoading = ref(false)
const showSuccess = ref(false)

const hsPortalId = computed(
  () => props.portalId ?? config.public.hubspotPortalId,
)
const hsRegion = computed(() => props.region ?? config.public.hubspotRegion)

const onFormSubmitted = (
  form: HTMLFormElement,
  { submissionValues: values },
) => {
  sendLoading.value = false
  showSuccess.value = true
  emit('on-submitted', { form, values, refreshForm })
}

const onBeforeFormInit = () => {
  loading.value = true
  emit('on-before-init')
}

const setOnInputWebsiteUrl = (form: HTMLFormElement) => {
  if (import.meta.server) return

  const websiteInput: HTMLInputElement = form.querySelector(
    'input[name*="form_completed_on"]',
  )
  if (websiteInput) websiteInput.value = window.location.href
}

const setEmailWhenHidden = (form: HTMLFormElement) => {
  if (import.meta.server) return

  const emailInput: HTMLInputElement = form.querySelector('input[name="email"]')

  if (!emailInput || emailInput?.type !== 'hidden') return

  emailInput.value = userEmail.value
}

const setCountryName = (form: HTMLFormElement) => {
  if (import.meta.server) return

  const countryInput: HTMLSelectElement = form.querySelector(
    'select[id^="phone_ext"]',
  )
  const countryName: HTMLInputElement = form.querySelector(
    'input[name*="hs_country_region_code"]',
  )

  if (!countryInput || !countryName) return

  countryName.value = countryInput.value
}

const onFormReady = (form: HTMLFormElement) => {
  setOnInputWebsiteUrl(form)
  setEmailWhenHidden(form)

  emit('on-ready', form)
  loading.value = false
  showSuccess.value = false
}

const onBeforeFormSubmit = (form: HTMLFormElement, values: TCommon) => {
  sendLoading.value = true
  setCountryName(form)
  emit('on-before-submit', { form, values })
}

const loadForm = (formId: string) => {
  if (import.meta.server || !window?.hbspt) return

  nextTick(() => {
    window?.hbspt.forms.create({
      formInstanceId,
      formId,
      region: hsRegion.value,
      portalId: hsPortalId.value,
      target: `#${containerId}`,
      onFormSubmitted,
      onBeforeFormSubmit,
      onBeforeFormInit,
      onFormReady,
    })
  })
}

const refreshForm = () => {
  loadForm(props.formId)
  loading.value = false
  showSuccess.value = false
}

const closeForm = () => {
  refreshForm()
  emit('close')
}

watch(
  () => props.formId,
  (formId) => {
    loadForm(formId)
  },
  { immediate: true },
)
</script>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core'
import IconCloseCircle from '@/assets/icons/close-circle.svg'
import IconLoading from '@/assets/icons/loading.svg'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },

  /**
   * Sometimes the content passed through <slot> needs external data
   * in order to be completely rendered. In such cases we set `async`
   * to `true` to show a loading spinner while its <slot> content is
   * loading; that way we avoid layout shifts within the modal.
   *
   * To notify BaseModal that it has loaded, the component we pass
   * through <slot> should call `onLoaded` (available to it via
   * "Scoped Slots").
   *
   * Related docs:
   *  - https://vuejs.org/guide/components/slots.html#scoped-slots
   */
  async: {
    type: Boolean,
    default: false,
  },
  bgWhite: {
    type: Boolean,
    default: false,
  },
  showMessage: {
    type: Boolean,
    default: false,
  },
  contentClass: {
    type: String,
    default: '',
  },
})

const { $modal } = useNuxtApp()

const isOpen = computed(() => $modal[props.name])
const loading = ref(false)

const close = () => {
  $modal.close(props.name)
  loading.value = props.async
}

onKeyStroke('Escape', close)

const handleLoaded = () => {
  loading.value = false
}

onBeforeMount(() => {
  loading.value = props.async
})
</script>

<template>
  <Teleport to="body">
    <transition
      enter-from-class="opacity-0"
      enter-active-class="transition-opacity duration-200"
      leave-active-class="transition-opacity duration-500"
      leave-to-class="opacity-0"
    >
      <div
        v-if="isOpen"
        class="fixed left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-s-900 bg-opacity-95"
        :class="$attrs.class"
        :data-testid="$attrs['data-testid']"
        @click.self="close"
      >
        <IconLoading v-show="loading" class="my-28 size-7 text-p-500" />

        <div
          v-show="!loading"
          class="relative flex max-h-[calc(100vh_-_2rem)] rounded-xl"
          :class="props.bgWhite ? 'bg-white' : 'bg-s-50'"
        >
          <div class="overflow-auto pb-10 pt-5">
            <button
              :data-testid="`close-${name}-modal-btn`"
              class="w-full px-4"
              @click.prevent="$modal.close(name)"
            >
              <IconCloseCircle class="ml-auto size-9" />
            </button>
            <div class="px-10" :class="contentClass">
              <slot v-if="!showMessage" :on-loaded="handleLoaded" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

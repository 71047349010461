import VueGtag from 'vue-gtag-next'
import { acceptCookieByCategory } from '~/utils/cookie-script'
import { COOKIE_SCRIPT_CATEGORIES } from '~/constants/cookie-script.constant'

export default defineNuxtPlugin({
  name: 'google-analytics',
  dependsOn: ['cookies'],
  setup(nuxtApp) {
    const { $config, $cookies, vueApp } = nuxtApp

    const acceptTargetingCookies = acceptCookieByCategory(
      $cookies,
      COOKIE_SCRIPT_CATEGORIES.PERFORMANCE,
    )

    vueApp.use(VueGtag, {
      isEnabled: $config.public.isProduction && acceptTargetingCookies,
      property: {
        id: $config.public.googleAnalyticsKey,
      },
    })
  },
})

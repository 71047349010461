<script setup lang="ts">
import IconLinkedIn from '@/assets/icons/linkedin.svg'
import IconTwitter from '@/assets/icons/twitter.svg'
import IconNewsletter from '@/assets/icons/newsletter.svg'
import IconLink from '@/assets/icons/link.svg'

const props = defineProps({
  iconClasses: {
    type: String,
    default: 'h-5 w-5 fill-s-900',
  },
  hideNewsletter: {
    type: Boolean,
    default: false,
  },
  twitterLink: {
    type: String,
    default: null,
  },
  linkedInLink: {
    type: String,
    default: null,
  },
  hideShareButton: {
    type: Boolean,
    default: false,
  },
  idArticle: {
    type: String,
    default: null,
  },
})

const $config = useRuntimeConfig()

const twitterUrl = computed(() =>
  props.twitterLink ? props.twitterLink : $config.public.links.twitter,
)

const linkedInUrl = computed(() =>
  props.linkedInLink ? props.linkedInLink : $config.public.links.linkedIn,
)

const copyMsgKey = ref(0)

const copyToClipboard = () => {
  const url = props.idArticle
    ? `${window.location.href}/${props.idArticle}`
    : window.location.href
  navigator.clipboard.writeText(url)
  copyMsgKey.value++
}
</script>

<template>
  <div class="flex">
    <a :href="linkedInUrl" target="_blank">
      <IconLinkedIn :class="iconClasses" />
    </a>
    <a :href="twitterUrl" target="_blank">
      <IconTwitter :class="iconClasses" />
    </a>
    <button
      v-if="!hideShareButton"
      type="button"
      class="relative flex justify-center"
      title="Copy Link"
      @click="copyToClipboard"
    >
      <IconLink />
      <div
        v-if="copyMsgKey"
        :key="copyMsgKey"
        class="copied-msg absolute flex cursor-default justify-center rounded-lg bg-s-900 px-3 py-1 text-center text-xs text-white"
      >
        <div></div>
        Copied!
      </div>
    </button>
    <LinkOrAnchor v-if="!hideNewsletter" to="/newsletter">
      <IconNewsletter :class="iconClasses" />
    </LinkOrAnchor>
  </div>
</template>

<style scoped>
.copied-msg {
  top: calc(100% + 10px);
  animation: show-and-hide 3s ease-out forwards;
}

.copied-msg > div {
  position: absolute;
  top: -10px;
  border: 5px solid #222;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

@keyframes show-and-hide {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>

<script setup lang="ts">
import { Form, Field } from 'vee-validate'
import { handleError } from '@/utils/form'
import IconLoading from '@/assets/icons/loading.svg'
import IconCheck from '@/assets/icons/check.svg'

const props = withDefaults(
  defineProps<{
    isPage: boolean
  }>(),
  {
    isPage: true,
  },
)

const { $restClient } = useNuxtApp()

const initialValues = { email: '', source: 'source' }

const { handleSubmit, successMessage } = useForm({
  initialValues,
  fetcher: $restClient.requestResetPasswordLink,
  onError: handleError,
})

const form = reactive(initialValues)
const count = ref(0)
const resetLoading = ref(false)

const disable = computed(() => count.value > 0)

const editEmailMessage = computed(() =>
  props.isPage ? 'Edit Email' : 'Back to Form',
)

const handleClick = async () => {
  count.value = 10
  resetLoading.value = true
  await $restClient.requestResetPasswordLink(form)
  resetLoading.value = false
  const interval = setInterval(() => {
    count.value--
    if (count.value === 0) clearInterval(interval)
  }, 1200)
}

const editEmail = () => {
  successMessage.value = ''
}
</script>

<template>
  <Form
    v-if="!Boolean(successMessage)"
    v-slot="{ isSubmitting }"
    data-testid="login-form"
    @submit="handleSubmit"
  >
    <h1 class="text-2xl font-bold">Reset Password</h1>
    <p class="mb-4 mt-10">
      Enter your email address that you used to register. We'll send you an
      email with your username and a link to reset your password.
    </p>

    <BaseInput
      v-model="form.email"
      data-testid="email"
      label="Email"
      name="email"
      type="email"
      placeholder="name@email.com"
      rules="required|email"
      is-focused
      :validate-on-blur="false"
      with-validation-color
    />

    <Field v-model="form.source" type="hidden" name="source" />

    <div
      class="mt-5 flex flex-col-reverse items-stretch justify-end gap-x-2 md:flex-row"
    >
      <BaseButton
        class="!px-10 !py-3 !text-sm"
        type="submit"
        :loading="isSubmitting"
      >
        Send
      </BaseButton>
    </div>
  </Form>
  <div v-else>
    <h1 class="text-2xl font-bold">Email has been sent</h1>
    <p class="mt-10">
      We have sent you a reset password link to {{ form.email }}
    </p>
    <div class="my-5 flex gap-x-2">
      <span>Didn’t receive the email?</span>
      <button
        :disabled="disable"
        class="flex gap-x-2 font-bold"
        @click="handleClick"
      >
        <span v-if="!disable" class="hover:text-p-500"> Send Again </span>
        <span v-else class="flex items-center gap-x-2">
          <IconLoading v-if="resetLoading" class="text-p-500" />
          <template v-else>
            <span class="text-green">Sent</span>
            <IconCheck class="text-green" />
            <span class="text-xs font-semibold"> Try again in </span>
            <span class="relative overflow-hidden text-xs font-semibold">
              <span class="count-down block w-4">{{ count }}</span>
            </span>
          </template>
        </span>
      </button>
    </div>

    <div class="flex justify-end gap-x-5">
      <BaseButton class="!text-sm" variant="tertiary" @click="editEmail">
        {{ editEmailMessage }}
      </BaseButton>
      <NuxtLink to="/">
        <BaseButton class="!text-sm"> Back to Homepage </BaseButton>
      </NuxtLink>
    </div>
  </div>
</template>

<style>
@keyframes count-down {
  0% {
    transform: translateY(100%);
  }

  60% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.count-down {
  animation: count-down 1.2s cubic-bezier(0, 1, 1, 1) infinite;
}
</style>

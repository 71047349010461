<script setup lang="ts">
import { VueInstance } from '@vueuse/core'
import { getCurrentInstance } from 'vue'
import { Field, ErrorMessage, validate, useFieldError } from 'vee-validate'
import IconEyeOn from '@/assets/icons/eye-on.svg'
import IconEyeOff from '@/assets/icons/eye-off.svg'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: String,
    required: false,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  rules: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  validateOnBlur: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  defaultValue: {
    type: String,
    default: '',
  },
  withValidationColor: {
    type: Boolean,
    default: false,
  },
})

const error = useFieldError(props.name)

const _type = ref(props.type)
const field = ref<VueInstance>()
const instance = getCurrentInstance()
const id = `input-${instance.uid}`

const value = computed({
  get: () => props.modelValue,
  set: (newValue) => emit('update:modelValue', newValue),
})

const toggleType = () => {
  _type.value === 'password'
    ? (_type.value = 'text')
    : (_type.value = 'password')
}

const isEmail = computed(
  () => props.type === 'email' || props.rules.match(/email/),
)

watch(value, async (newValue) => {
  if (!isEmail.value) return

  const { valid } = await validate(newValue, props.rules)

  if (valid) {
    field.value.$el.dispatchEvent(new Event('blur'))
  }
})

const emit = defineEmits(['update:modelValue'])
const borderColors = {
  success: 'focus-within:!ring-green valid:!border-green !border-green',
  error: 'focus-within:!ring-error valid:!border-error !border-error',
  neutral: 'focus-within:!ring-p-500 !border-s-500',
}
const borderClasses = ref('focus-within:ring-p-500 border-s-500')

watch(
  [() => props.withValidationColor, error],
  ([withValidationColor, error]) => {
    if (!withValidationColor) {
      borderClasses.value = borderColors.neutral
      return
    }

    if (error) {
      borderClasses.value = borderColors.error
      return
    }

    borderClasses.value = borderColors.success
  },
)

onMounted(() => {
  props.isFocused && field.value.$el.focus()

  if (props.defaultValue) {
    value.value = props.defaultValue
  }
})
</script>

<template>
  <div>
    <label :for="id" class="mb-2 text-xs font-semibold">
      {{ label }} <span v-if="required" class="text-error">*</span>
    </label>
    <div
      class="form-input relative flex w-full items-center gap-x-2 overflow-hidden rounded border border-s-600 p-0 transition-colors focus-within:border-p-500"
      :class="borderClasses"
    >
      <Field
        :id="id"
        ref="field"
        v-model="value"
        :name="name"
        :rules="rules"
        class="w-full border-none bg-transparent px-3 py-1 placeholder-s-400 ring-0 !ring-transparent focus:!outline-0 focus:ring-0"
        :type="_type"
        :placeholder="placeholder"
        :validate-on-blur="validateOnBlur"
        :validate-on-input="!isEmail"
        :label="label"
      />

      <!-- Password Toggle -->
      <div
        v-if="type === 'password'"
        class="flex size-7 cursor-pointer items-center text-s-900"
        @click="toggleType"
      >
        <IconEyeOff v-show="_type === 'password'" />
        <IconEyeOn v-show="_type === 'text'" />
      </div>
    </div>

    <div class="min-h-4">
      <ErrorMessage class="block text-xxs text-error" :name="name" />
    </div>
  </div>
</template>

<style>
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}
</style>

const _0 = "0px"
const _1 = "0.25rem"
const _2 = "0.5rem"
const _3 = "0.75rem"
const _4 = "1rem"
const _5 = "1.25rem"
const _6 = "1.5rem"
const _7 = "1.75rem"
const _8 = "2rem"
const _9 = "2.25rem"
const _10 = "2.5rem"
const _11 = "2.75rem"
const _12 = "3rem"
const _14 = "3.5rem"
const _15 = "3.75rem"
const _16 = "4rem"
const _20 = "5rem"
const _24 = "6rem"
const _25 = "6.25rem"
const _28 = "7rem"
const _32 = "8rem"
const _36 = "9rem"
const _40 = "10rem"
const _44 = "11rem"
const _48 = "12rem"
const _52 = "13rem"
const _56 = "14rem"
const _60 = "15rem"
const _64 = "16rem"
const _72 = "18rem"
const _80 = "20rem"
const _96 = "24rem"
const _auto = "auto"
const _px = "1px"
const _small = "18px"
const _full = "100%"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "14": _14, "15": _15, "16": _16, "20": _20, "24": _24, "25": _25, "28": _28, "32": _32, "36": _36, "40": _40, "44": _44, "48": _48, "52": _52, "56": _56, "60": _60, "64": _64, "72": _72, "80": _80, "96": _96, "auto": _auto, "px": _px, "small": _small, "full": _full, "0.5": "0.125rem", "1.5": "0.375rem", "2.5": "0.625rem", "3.5": "0.875rem", "4.5": "1.125rem", "1/2": "50%", "9/16": "56.25%", "1/12": "8.333333%", "line-2": "2px", "line-4": "4px", "line-6": "6px", "1/3": "33.333333%", "2/3": "66.666667%", "1/4": "25%", "2/4": "50%", "3/4": "75%",  }
export { config as default, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _14, _15, _16, _20, _24, _25, _28, _32, _36, _40, _44, _48, _52, _56, _60, _64, _72, _80, _96, _auto, _px, _small, _full }
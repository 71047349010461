import { format, parseISO } from 'date-fns'
import { TIME_FORMAT } from '@/constants/app'
import {
  SUBSCRIPTIONS,
  SUBSCRIPTIONS_BY_SLUGS,
} from '@/constants/memberships.const'
import { parseMagazineTitle, removeAnchorWithHref } from '@/utils/string'
import { keysToCamelCase } from '@/utils/object'
import { reformatDate } from '@/utils/date'
import { SearchResponse } from '@/models/request'
import {
  Article,
  Video,
  Magazine,
  Presentation,
  TickerIndexSummary,
  Category,
  SpecialIssue,
  MagazineDetail,
  MagazineDetailRaw,
} from '@/models/content'
import { Subscription } from '@/models/subscriptions.types'
import { MembershipStatus } from '@/models/membership'
import { UserInfo } from '@/models/auth'

function filterSource(category: Category) {
  if (category.name.toLowerCase() !== 'source') return true
}

export function getAdaptedVideo({ video }): Video {
  const { id, slug, title, date } = video
  const { previewVimeoId, speaker, vimeoId, isPublic, description } =
    video.videos
  let categories = video.categories.nodes
  const tags = video.tags.nodes

  categories = categories.filter(filterSource)

  let publishedDate = ''
  try {
    publishedDate = reformatDate(date, 'yyyy-MM-dd HH:mm:ss')
  } catch (e) {
    try {
      const _date = new Date(date)

      publishedDate = format(_date, TIME_FORMAT)
    } catch (_e) {}
  }

  const coverUrl =
    video.videos.thumbnail?.node?.mediaItemUrl || '/images/fallbacks/video.svg'
  const duration = video.videos.duration

  return {
    id,
    contentType: 'video',
    publishedDate,
    duration,
    /*
      If we are on the preview site, we want to retrieve
      video drafts, which don't have a slug. Therefore,
      since we use the slug field to link cards to detail
      pages, we decided to fill it with the id of the
      video draft.
    */
    slug: slug || id,
    title,
    excerpt: removeAnchorWithHref(speaker),
    description,
    coverUrl,
    categories,
    vimeoId,
    previewVimeoId,
    isPublic,
    tags,
    relatedVideos: [],
    date,
  }
}

export function getAdaptedVideos({ videos }): Video[] {
  return videos.nodes.map((video) => getAdaptedVideo({ video }))
}

export function getAdaptedArticle({ article }): Article {
  const { id, slug, title, excerpt, content, memberships, date } = article
  let categories = article.categories?.nodes || []
  const tags = article.tags?.nodes || []

  categories = categories.filter(filterSource)

  const coverCaption =
    article.featuredImage?.node?.caption &&
    article.featuredImage?.node?.caption !==
      article.featuredImage?.node?.description
      ? article.featuredImage?.node?.caption
      : null
  const quote = memberships.quote?.trim()
  const publishedDate = reformatDate(article.date, "yyyy-MM-dd'T'HH:mm:ss")
  const duration = memberships.duration

  const hasChart = tags.some((tag) => tag.name === 'visualization')

  const coverUrl =
    article.featuredImage?.node?.mediaItemUrl || '/images/fallbacks/article.svg'

  const squareUrl = memberships.thumbnailSquare?.node?.sourceUrl || ''

  const author = {
    company: memberships.authorInfo?.company || '',
    fullName: memberships.authorInfo?.fullName || '',
    jobTitle: memberships.authorInfo?.jobTitle || '',
  }

  const thumbnails = {
    squareUrl,
  }

  return {
    id,
    contentType: hasChart ? 'chart' : 'article',
    publishedDate,
    duration: !duration ? 1 : duration,
    /*
      If we are on the preview site, we want to retrieve
      article drafts, which don't have a slug. Therefore,
      since we use the slug field to link cards to detail
      pages, we decided to fill it with the id of the
      article draft.
    */
    slug: slug || id,
    title,
    content,
    excerpt: removeAnchorWithHref(excerpt),
    quote,
    coverUrl,
    coverCaption,
    categories,
    tags,
    viewerCanRead: article.viewerCanRead,
    seo: article.seo,
    relatedArticles: [],
    thumbnails,
    author,
    date,
  }
}

export function getAdaptedArticles({ articles }): Article[] {
  return articles.nodes.map((article) => getAdaptedArticle({ article }))
}

export function getAdaptedMagazine(magazine): Magazine {
  const [quarter, title, issue] = parseMagazineTitle(magazine.post_title)

  let categories = magazine.categories || []

  const publishedDate = reformatDate(magazine.post_date, 'yyyy-MM-dd HH:mm:ss')

  categories = categories.filter(filterSource)

  return {
    id: magazine.ID.toString(),
    contentType: 'magazine',
    publishedDate,
    title,
    summary: magazine.post_content,
    linkFlipHTML5: magazine.magazine_link_fliphtml5 ?? null,
    quarter,
    issue,
    categories,
    tags: magazine.tags || [],
    coverUrl: magazine.thumbnail_url || '/images/fallbacks/magazine.svg',
    coverCaption: null,
    fileName: magazine.file_name,
    fileCategory: 'magazine',
  }
}

export function getAdaptedMagazines(magazines): Magazine[] {
  return magazines.map((magazine) => getAdaptedMagazine(magazine))
}

export function getAdaptedMagazineDetail(
  magazine: MagazineDetailRaw,
): MagazineDetail {
  const data = parseMagazineTitle(magazine.title)
  return {
    id: magazine.id,
    title: data[1],
    description: magazine.description,
    thumbnailUrl: magazine.thumbnail_url,
  }
}

export function getAdaptedPresentation(presentation): Presentation {
  const coverUrl =
    presentation.thumbnail_url || '/images/fallbacks/presentation.svg'
  let categories = presentation.categories || []

  const publishedDate = reformatDate(
    presentation.post_date,
    'yyyy-MM-dd HH:mm:ss',
  )

  categories = categories.filter(filterSource)

  return {
    id: presentation.ID.toString(),
    contentType: 'presentation',
    title: presentation.post_title,
    publishedDate,
    unixPublishedDate: parseISO(presentation.post_date).getTime(),
    categories,
    tags: presentation.tags || [],
    coverUrl,
    coverCaption: null,
    fileName: presentation.file_name,
    fileCategory: 'membership',
    company: presentation.company,
    event: presentation.event,
    linkFlipHTML5: presentation.presentation_link_fliphtml5,
  }
}

export function getAdaptedPresentations(presentations): Presentation[] {
  return presentations.map((presentation) =>
    getAdaptedPresentation(presentation),
  )
}

export function getAdaptedTickerIndexes(indexes: any): TickerIndexSummary[] {
  return indexes?.map((index: any) => {
    return {
      name: index.label,
      today: index.today,
      percentageChange: index.percentageChange,
      publishedDate: index.publishedDate,
      hasAccess: index.hasAccess,
      isProtected: index.isProtected,
      updateFrequency: index.updateFrequency,
      updateFrequencyText: index.updateFrequencyText,
    }
  })
}

export function getAdaptedFeaturedSubcategory({ category }) {
  return category.acf.subcategories
    ? category.acf.subcategories[0].slug
    : undefined
}

export function getAdaptedContents({ contentNodes }): (Article | Video)[] {
  const contents: (Article | Video)[] = []

  contentNodes.edges.forEach((edge) => {
    const type = edge.node.__typename

    if (type === 'Membership') {
      contents.push(getAdaptedArticle({ article: edge.node }))
    } else if (type === 'Video') {
      contents.push(getAdaptedVideo({ video: edge.node }))
    }
  })

  return contents
}

export function getAdaptedFormidableErrors({ errors, form }) {
  const messages = {}

  for (const fieldId in errors) {
    const field = form.value.fields.find((_field) => _field.id === fieldId)
    messages[field.name] = errors[fieldId]
  }
  return messages
}

export function getAdaptedChartData(detail: any[], colors: string[]) {
  const datasets = []
  let labels = []
  detail?.forEach((item, index) => {
    datasets.push({
      data: item.data?.map(({ value }) => value),
      backgroundColor: colors[index],
      borderColor: colors[index],
      label: item.product,
    })
    const dates = item.data?.map(({ date }) => date) ?? []
    const setDates = new Set([...labels, ...validateDates(dates)])
    labels = Array.from(setDates)
  })
  return {
    datasets,
    labels,
  }
}

function validateDates(dates: string[]) {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  return dates.filter((date) => new Date(date) <= tomorrow)
}

export function getAdaptedUser(user): UserInfo {
  const roles: string[] = user.roles?.nodes.map((role) => role.name) || []

  return {
    id: user.id || '',
    name: user.name || '',
    email: user.email || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    roles,
    canPreviewContent: roles.some((role) =>
      ['administrator', 'editor', 'author'].includes(role),
    ),
  }
}

export function getRestAdaptedVideo(video): Video {
  const newObj = keysToCamelCase(video)

  newObj.id = String(newObj.id)

  try {
    newObj.publishedDate = reformatDate(
      newObj.publishedDate,
      'yyyy-MM-dd HH:mm:ss',
    )
  } catch (e) {
    try {
      const date = new Date(newObj.publishedDate)

      newObj.publishedDate = format(date, TIME_FORMAT)
    } catch (_e) {}
  }

  newObj.duration = Number(newObj.duration)
  const coverUrl = newObj.coverUrl || '/images/fallbacks/video.svg'

  return {
    id: newObj.id,
    contentType: newObj.contentType,
    publishedDate: newObj.publishedDate,
    duration: newObj.duration,
    slug: newObj.slug,
    title: newObj.title,
    excerpt: removeAnchorWithHref(newObj.speaker),
    coverUrl,
    categories: newObj.categories,
    vimeoId: newObj.vimeoId,
    tags: newObj.tags,
    relatedVideos: [],
    date: newObj.publishedDate,
  }
}

export function getRestAdaptedArticle(article): Article {
  const newObj = keysToCamelCase(article)

  newObj.id = String(newObj.id)

  newObj.publishedDate = reformatDate(
    newObj.publishedDate,
    'yyyy-MM-dd HH:mm:ss',
  )

  newObj.duration = !newObj.duration ? 1 : Number(newObj.duration)
  const coverUrl = newObj.coverUrl || '/images/fallbacks/article.svg'

  return {
    id: newObj.id,
    contentType: newObj.contentType,
    publishedDate: newObj.publishedDate,
    duration: newObj.duration,
    slug: newObj.slug,
    title: newObj.title,
    content: '',
    excerpt: removeAnchorWithHref(newObj.excerpt),
    quote: newObj.quote,
    coverUrl,
    categories: newObj.categories,
    tags: newObj.tags,
    relatedArticles: [],
    viewerCanRead: false,
    seo: null,
    date: newObj.publishedDate,
  }
}

export function getRestAdaptedMagazine(magazine): Magazine {
  const newObj = keysToCamelCase(magazine)

  newObj.id = String(newObj.id)

  newObj.publishedDate = reformatDate(
    newObj.publishedDate,
    'yyyy-MM-dd HH:mm:ss',
  )

  const [quarter, title, issue] = parseMagazineTitle(magazine.title)
  const coverUrl = newObj.coverUrl || '/images/fallbacks/magazine.svg'

  return {
    id: newObj.id,
    contentType: newObj.contentType,
    publishedDate: newObj.publishedDate,
    title,
    quarter,
    issue,
    summary: removeAnchorWithHref(newObj.excerpt),
    linkFlipHTML5: newObj.linkFlipHTML5,
    categories: newObj.categories,
    tags: newObj.tags,
    coverUrl,
    fileName: newObj.fileName,
    fileCategory: 'magazine',
  }
}

export function getRestAdaptedPresentation(presentation): Presentation {
  const newObj = keysToCamelCase(presentation)

  newObj.id = String(newObj.id)

  newObj.publishedDate = reformatDate(
    newObj.publishedDate,
    'yyyy-MM-dd HH:mm:ss',
  )

  newObj.unixPublishedDate = parseISO(newObj.publishedDate).getTime()
  const coverUrl = newObj.coverUrl || '/images/fallbacks/presentation.svg'

  return {
    id: newObj.id,
    contentType: newObj.contentType,
    title: newObj.title,
    publishedDate: newObj.publishedDate,
    unixPublishedDate: newObj.unixPublishedDate,
    categories: newObj.categories,
    tags: newObj.tags,
    coverUrl,
    fileName: newObj.fileName,
    fileCategory: 'membership',
    company: newObj.company,
    event: newObj.event,
    linkFlipHTML5: newObj.presentationLinkFliphtml5,
  }
}

export function getRestAdaptedContents(response: SearchResponse) {
  const restAdapters = {
    article: getRestAdaptedArticle,
    video: getRestAdaptedVideo,
    magazine: getRestAdaptedMagazine,
    presentation: getRestAdaptedPresentation,
  }

  const data = response.data.map((obj) => {
    const type = obj.content_type

    return restAdapters[type](obj)
  })

  const { total } = response

  return {
    total,
    data,
  }
}

export function getAdaptedFeaturedCategoryData(res) {
  const data = res.data?.category?.acf

  const subCategories = data.subcategories
    ? data.subcategories.map((obj) => obj.name)
    : []
  const subTags = data.subtags ? data.subtags.map((obj) => obj.name) : []

  return {
    sectionTitle: data.title ? data.title : '',
    subCategories,
    subTags,
  }
}

function getAdaptedSubscription(subs: MembershipStatus): Subscription {
  const { slug, status } = subs
  let expirationDate = subs.expiration_date
  const name = SUBSCRIPTIONS_BY_SLUGS[slug].name

  if (status === 'active' && expirationDate === 'none') {
    expirationDate = 'Never'
  } else if (expirationDate !== 'none') {
    expirationDate = format(Date.parse(expirationDate), 'dd/MM/yyyy')
  }

  return {
    name,
    slug,
    status,
    expirationDate,
  }
}

export function getAdaptedMembershipStatuses(
  membershipStatuses: MembershipStatus[],
) {
  const productsAndServices: Record<string, Subscription[]> = {}
  const productNames = {
    Pricing: 'Price Assessments',
    'Market-Assessment': 'Market Assessments',
  }

  membershipStatuses.forEach((membership) => {
    const splittedName = membership.name.split(': ')
    let product: string

    if (splittedName.length > 1) {
      product = splittedName[0]
      membership.name = splittedName[1]
    } else {
      product = 'Source'
    }

    if (membership.name === 'Gigafactory') membership.name = 'Battery Cells'

    if (productNames[product]) product = productNames[product]

    if (!productsAndServices[product]) productsAndServices[product] = []

    productsAndServices[product].push(getAdaptedSubscription(membership))
  })

  for (const [key, value] of Object.entries(SUBSCRIPTIONS)) {
    if (!productsAndServices[key]) {
      productsAndServices[key] = [...value]
      continue
    }

    value.forEach((subscription) => {
      if (
        !productsAndServices[key].find((sub) => sub.slug === subscription.slug)
      ) {
        productsAndServices[key].push(subscription)
      }
    })
  }

  return {
    'Price Assessments': productsAndServices['Price Assessments'],
    Forecast: productsAndServices.Forecast,
    Source: productsAndServices.Source,
    'Market Assessments': productsAndServices['Market Assessments'],
    Sustainability: productsAndServices.ESG,
  }
}

export function getAdaptedSpecialIssues(specialIssues): SpecialIssue[] {
  return specialIssues.map((specialIssue) =>
    getAdaptedSpecialIssue(specialIssue),
  )
}

export function getAdaptedSpecialIssue(specialIssue): SpecialIssue {
  const title = specialIssue.post_title

  let categories = specialIssue.categories || []

  const publishedDate = reformatDate(
    specialIssue.post_date,
    'yyyy-MM-dd HH:mm:ss',
    'MMMM yyyy',
  )

  categories = categories.filter(filterSource)

  return {
    id: specialIssue.ID.toString(),
    contentType: 'special-issue',
    publishedDate,
    title,
    summary: specialIssue.post_content,
    issue: specialIssue.issue,
    categories,
    tags: specialIssue.tags || [],
    coverUrl: specialIssue.thumbnail_url || '/images/fallbacks/magazine.svg',
    coverCaption: null,
    fileName: specialIssue.file_name,
    fileCategory: 'special-issues',
    formId: specialIssue.special_issues_form_id,
    linkFlipHTML5: specialIssue.special_issues_link_fliphtml5,
  }
}

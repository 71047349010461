<script setup lang="ts">
import DefaultLayout from '~/layouts/default.vue'

const props = defineProps({
  error: {
    type: Object,
  },
})

const statusCode = computed(() => Number(props.error.statusCode))
</script>

<template>
  <DefaultLayout>
    <div>
      <div
        v-if="statusCode === 404"
        class="flex h-full grow items-center justify-center px-5 py-20"
      >
        <div class="flex flex-col items-center">
          <img
            src="https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto/v1672543994/source-assets/404_ubsezc_pnz5qw.avif?_i=AA"
            alt="404"
            width="800"
            height="400"
            class="h-auto w-full max-w-[680px]"
          />

          <p class="my-6 text-center text-lg font-semibold md:mt-10 lg:mt-20">
            Sorry, we were unable to find the page you’re looking for
          </p>

          <BaseLink href="/">Go to homepage</BaseLink>
        </div>
      </div>

      <!-- 404 Error -->
      <div
        v-else-if="statusCode === 403"
        class="flex h-full grow items-center justify-center px-5 py-20"
      >
        <div class="flex flex-col items-center">
          <img
            src="https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto/v1672543994/source-assets/404_ubsezc_pnz5qw.avif?_i=AA"
            alt="404"
            width="800"
            height="400"
            class="h-auto w-full max-w-[680px]"
          />

          <p class="my-6 text-center text-lg font-semibold md:mt-10 lg:mt-20">
            Unauthorized user, you do not have permission to view this page.
          </p>

          <BaseLink href="/">Go to homepage</BaseLink>
        </div>
      </div>

      <!-- 500 Error -->
      <div
        v-else
        class="flex h-full grow items-center justify-center px-5 py-20"
      >
        <div class="flex flex-col items-center">
          <div>
            <h2 class="text-center text-9xl font-semibold">500</h2>
          </div>

          <p class="my-6 text-center text-lg font-semibold md:mt-10">
            Internal Server Error. Please refresh, if the problem persists,
            please
            <a
              href="mailto:support.source@benchmarkminerals.com"
              class="text-s-900"
            >
              <u>contact us.</u>
            </a>
          </p>

          <BaseLink href="/">Go to homepage</BaseLink>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script setup lang="ts">
import IconBenchmarkLogo from '@/assets/icons/benchmark-logo.svg'
import IconSearch from '@/assets/icons/search.svg'
import { useAuthStore } from '@/stores/auth'

const authStore = useAuthStore()
</script>

<template>
  <header class="py-3">
    <div
      class="mx-auto flex max-w-content-container items-center justify-between px-5"
    >
      <!-- Left -->
      <SocialLinks
        class="w-[30%] gap-4"
        icon-classes="h-5 w-5 fill-s-900"
        hide-share-button
      />

      <!-- Center -->
      <LinkOrAnchor
        class="flex w-[40%] flex-col items-center justify-center text-center"
        to="/"
      >
        <IconBenchmarkLogo class="w-[334px]" />
        <p class="mt-1.5 text-[13.098px] tracking-[0.01em] text-s-600">
          Supply Chain Intelligence for the Energy Transition
        </p>
      </LinkOrAnchor>

      <!-- Right -->
      <div class="w-[30%]">
        <ClientOnly>
          <div
            v-if="authStore.token !== undefined"
            class="flex items-center justify-end gap-4"
          >
            <BaseLink
              variant="tertiary"
              class="border-none px-0 py-0"
              href="/about"
            >
              About
            </BaseLink>
            <span class="h-8 border-r-2 border-s-200"></span>
            <div
              v-if="!authStore.isAuthenticated"
              class="flex items-center justify-end gap-2 navigation-adjustment:gap-4"
            >
              <a
                href="/login"
                class="text-xs font-semibold"
                data-testid="login-link"
                @click.prevent="$modal.open('login')"
              >
                Login
              </a>
              <span class="h-8 border-r-2 border-s-200"></span>
              <BaseLink variant="tertiary" href="/request-a-trial">
                Request a trial
              </BaseLink>
            </div>

            <div v-if="authStore.isAuthenticated" class="text-xs font-semibold">
              <div class="relative" @mouseleave="$modal.close('accountMenu')">
                <button
                  data-testid="login-link"
                  @click="$modal.toggle('accountMenu')"
                >
                  Account
                </button>
                <AccountMenu v-if="$modal.accountMenu" />
              </div>
            </div>

            <LinkOrAnchor to="/search">
              <IconSearch />
            </LinkOrAnchor>
          </div>
        </ClientOnly>
      </div>
    </div>
  </header>
</template>

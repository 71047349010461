export const allMediums = [
  { value: 'membership', label: 'Articles' },
  { value: 'video', label: 'Videos' },
  { value: 'magazine', label: 'Magazines' },
  { value: 'presentation', label: 'Presentations' },
]

export const NON_PUBLISHED_POST_STATES = ['DRAFT', 'FUTURE']
export const PUBLISHED_POST_STATES = ['PUBLISH']

export const CHART_ARTICLE_TAG = 'data-visualisation'
export const BENCHMARK_PRESENTATION_CATEGORY = 'benchmark-mineral-intelligence'

export const POST_TYPES = ['membership', 'magazine', 'video', 'presentation']

export const NEWSLETTERS = [
  {
    title: 'Benchmark Newsletter',
    description:
      'Your go-to for Benchmark’s expert view on energy transition markets including raw materials, midstream components, batteries and gigafactories. You can also find out about our latest events schedule hosted in locations across the globe.',
    img: 'https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_220/v1739589409/assets/newsletter/newsletter-4_xzxwfo.jpg',
    alt: 'Benchmark Newsletter',
  },
  {
    title: 'Price Spotlight',
    description:
      'Stay up to date with EV battery supply chain price indexes, derived from Benchmark’s world-leading Price Assessments, direct to your inbox every week.',
    img: 'https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_220/v1739589409/assets/newsletter/newsletter-1_dfgabr.jpg',
    alt: 'Price Spotlight Newsletter',
  },
  {
    title: 'Geo-economic & Policy Newsletter',
    description:
      'Receive biweekly updates on government policy changes shaping energy transition supply chains, including expert analysis, opinions, and digestible infographics, directly to your inbox.',
    img: 'https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_220/v1739589409/assets/newsletter/newsletter-2_qdpaul.jpg',
    alt: 'Geo-economic & Policy Newsletter',
  },
  {
    title: 'Copper Weekly',
    description:
      'Keep ahead of the curve with weekly updates on the evolving role copper plays in the energy transition, copper prices, market dynamics and firsthand insights from Benchmark’s Beijing-based analyst on the Chinese market.',
    img: 'https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_220/v1739589409/assets/newsletter/newsletter-3_kcz44c.jpg',
    alt: 'Copper Weekly Newsletter',
  },
]

<script setup lang="ts">
import { PropType } from 'vue'
import { LevelOneLink, LevelTwoLink } from '@/models/navigation'
import IconAngleDown from '@/assets/icons/angle-down.svg?component'
import { useAppStore } from '~/stores/app'

const props = defineProps({
  levelOneLink: {
    type: Object as PropType<LevelOneLink>,
    required: true,
  },
  isContextual: {
    type: Boolean,
    default: false,
  },
})

const isUnfolded = ref(false)
const router = useRouter()

const app = useAppStore()

const onClickLevelOne = () => {
  if (!props.levelOneLink.children) {
    if (app.isDifferentAppVersion) {
      window.location.assign(getLevelOnePath(props.levelOneLink))
    } else {
      router.push(getLevelOnePath(props.levelOneLink))
    }
  } else {
    isUnfolded.value = !isUnfolded.value
  }
}

const onClickLevelTwo = (link: LevelTwoLink) => {
  if (app.isDifferentAppVersion) {
    window.location.assign(getLevelTwoPath(link, props.levelOneLink))
  } else {
    router.push(getLevelTwoPath(link, props.levelOneLink))
  }
}

const { getLevelOnePath, getLevelTwoPath, activeLevelOne, activeLevelTwo } =
  useNavigation()

router.afterEach(() => {
  isUnfolded.value = false
})
</script>

<template>
  <div>
    <a
      data-testid="level-one-link"
      :href="getLevelOnePath(levelOneLink)"
      class="mx-4 flex items-center justify-between border-b-1 border-s-300 py-2 text-lg"
      :class="{
        'font-semibold': levelOneLink === activeLevelOne,
        'mx-0 border-none bg-s-900 px-4 text-white': isContextual,
      }"
      @click.prevent="onClickLevelOne"
    >
      <div class="flex gap-x-2">
        <span :class="isContextual ? 'text-sm' : 'text-lg'">
          {{ levelOneLink.label }}
        </span>

        <span v-if="isContextual && activeLevelTwo" class="text-sm font-normal">
          {{ activeLevelTwo.label }}
        </span>
      </div>
      <IconAngleDown
        v-if="levelOneLink.children"
        class="h-6 w-6 origin-center duration-500"
        :class="isUnfolded ? '-rotate-180 ' : ''"
      />
    </a>

    <div
      v-if="levelOneLink.children"
      class="scale-y-0"
      :class="[
        isUnfolded ? 'unfold-animation' : 'fold-animation',
        isContextual
          ? 'absolute z-10 w-full bg-s-800 text-white shadow-contextual-subnavigation'
          : 'bg-[#DEDCDC]',
      ]"
    >
      <div
        :data-testid="
          isContextual ? 'contextual-subnavigation' : 'mobile-subnavigation'
        "
        class="opacity-0"
        :class="isUnfolded ? 'fade-in-animation' : 'hidden'"
      >
        <a
          v-for="link in levelOneLink.children"
          :key="link.path"
          :href="getLevelTwoPath(link, levelOneLink)"
          :class="{ 'font-bold': link === activeLevelTwo }"
          class="block px-4 py-2 pl-8 text-sm"
          data-testid="level-two-link"
          @click.prevent="onClickLevelTwo(link)"
        >
          {{ link.label }}
        </a>
      </div>
    </div>
  </div>
</template>

<style>
@keyframes unfold {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.unfold-animation {
  transform-origin: top left;
  animation: unfold 150ms 1 linear;
  animation-fill-mode: forwards;
}

.fade-in-animation {
  animation: fade-in 200ms 1 linear 150ms;
  animation-fill-mode: forwards;
}
</style>

<script setup lang="ts">
import { VueInstance } from '@vueuse/core'
import { Field, ErrorMessage } from 'vee-validate'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number],
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  rules: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['update:modelValue'])

const field = ref<VueInstance>()

onMounted(() => props.isFocused && field.value.$el.focus())
</script>

<template>
  <div>
    <label class="mb-1 flex flex-col">
      <span class="mb-1 block text-xs font-semibold">
        {{ label }} <span v-if="required" class="text-error">*</span>
      </span>

      <Field
        ref="field"
        :name="name"
        :rules="rules"
        as="textarea"
        class="form-input-outline form-textarea min-h-[168px] w-full rounded border border-s-600 px-3 py-1 placeholder-s-400 outline-1 outline-p-800"
        :placeholder="placeholder"
        :label="label"
        :value="props.modelValue"
        @update:model-value="(value) => $emit('update:modelValue', value)"
      />
    </label>

    <div class="h-4">
      <ErrorMessage class="block text-xxs text-error" :name="name" />
    </div>
  </div>
</template>

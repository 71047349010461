export const JWT_ACCESS_TOKEN = 'jwt_token'
export const REFRESH_TOKEN = 'refresh_token'
export const USER_INFO = 'user_info'
export const BACKEND_TOKEN_GENERATOR = 'backend_token_generator'
export const BACKEND_VERSION = 'backend_version'

const verifyRegexRule = (value: string, regex: RegExp) => {
  return regex.test(value)
}

export const PASSWORD_CRITERIA = [
  {
    id: 'atLeastEight',
    label: 'At least 8 characters',
    passed: false,
    rule: (value: string) => verifyRegexRule(value, /.{8,}/),
  },
  {
    id: 'oneUpper',
    label: 'One upper case letter',
    passed: false,
    rule: (value: string) => verifyRegexRule(value, /[A-Z]/g),
  },
  {
    id: 'oneLower',
    label: 'One lower case letter',
    passed: false,
    rule: (value: string) => verifyRegexRule(value, /[a-z]/g),
  },
  {
    id: 'oneNumerical',
    label: 'One numerical character',
    passed: false,
    rule: (value: string) => verifyRegexRule(value, /\d/g),
  },
  {
    id: 'passwordsMatch',
    label: 'Passwords match',
    passed: false,
    rule: (value: string, confirmValue: string) => value === confirmValue,
  },
]

export const BAR_COLORS = {
  error: '#D94761',
  success: '#2CA900',
  warning: '#FDAF00',
}

<script setup lang="ts">
import { Field, ErrorMessage } from 'vee-validate'
import IconAngleDown from '@/assets/icons/angle-down.svg'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
  },
  options: {
    type: [Array, Object],
    required: true,
  },
  defaultValue: {
    type: String,
    required: false,
  },
  modelValue: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  rules: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['update:modelValue'])
</script>

<template>
  <label>
    <span class="mb-1 block text-xs font-semibold">
      {{ label }} <span v-if="required" class="text-error">*</span>
    </span>

    <div
      class="group relative mb-1 block min-w-[140px] rounded border border-s-600 bg-white"
    >
      <!-- Arrow -->
      <div
        class="absolute right-2 top-2 flex h-4 w-4 items-center justify-center rounded-full transition-transform group-active:rotate-180"
      >
        <IconAngleDown />
      </div>

      <!-- Field -->
      <Field
        as="select"
        :name="name"
        :rules="rules"
        :label="label"
        class="relative h-full w-full cursor-pointer appearance-none rounded bg-transparent px-2.5 py-2 pr-10 text-xs font-semibold focus:outline-1 focus:outline-p-800"
        :value="props.modelValue"
        @input="
          $emit('update:modelValue', ($event.target as HTMLSelectElement).value)
        "
      >
        <option value="" selected disabled hidden>
          {{ placeholder ? placeholder : '--' }}
        </option>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </Field>
    </div>

    <div class="h-4">
      <ErrorMessage class="block text-xxs text-error" :name="name" />
    </div>
  </label>
</template>

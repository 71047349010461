import { defineStore } from 'pinia'
import { type TickerIndexSummary } from '@/models/content'

export const useIndexesStore = defineStore('indexes-store', {
  state: () => {
    return {
      indexesStates: [],
    } as {
      indexesStates: TickerIndexSummary[]
    }
  },

  actions: {
    setIndexesStates(states: TickerIndexSummary[]) {
      this.indexesStates = states
    },
  },
})

const _DEFAULT = "#3b82f6"
const _p = {"50":"#FFF7E0","100":"#FEEBB2","200":"#FEDE80","300":"#FDD24C","400":"#FDC723","500":"#FDBD00","600":"#FDAF00","700":"#FD9C00","800":"#FD8900","900":"#EA8000","em":{"high":"rgba(0, 0, 0, 0.87)"}}
const _s = {"50":"#FAFBFF","100":"#F3F5FC","150":"#D9D9D9","200":"#EFF1F9","300":"#E7E8F0","400":"#CACBD3","500":"#A4A5AA","600":"#7A7B80","700":"#66676C","800":"#494A4E","900":"#25262A"}
const _black = "#000000"
const _white = "#FFFFFF"
const _transparent = "rgba(255, 255, 255, 0)"
const _green = "#219653"
const _error = "#B00020"
const _neutral = "#1f62e7"
const _good = "#138800"
const _grey = {"50":"#FAFBFF","100":"#F3F5FC","200":"#DEE2EC","300":"#E7E8F0","400":"#CACBD3","600":"#7A7B80","900":"#25262A"}
const _semantic = {"good":"#219653","good-light":"#4bbf7c"}
const config = { "DEFAULT": _DEFAULT, "p": _p, "s": _s, "black": _black, "white": _white, "transparent": _transparent, "green": _green, "error": _error, "neutral": _neutral, "good": _good, "grey": _grey, "semantic": _semantic, "warm-gray": "#EFEFEF", "green-light": "#4BBF7C", "error-light": "#D94761",  }
export { config as default, _DEFAULT, _p, _s, _black, _white, _transparent, _green, _error, _neutral, _good, _grey, _semantic }
import { COOKIE_SCRIPT_KEY } from '~/constants/cookie-script.constant'

export const acceptCookieByCategory = (
  $cookiesInstance,
  category: string,
): boolean => {
  const cookieScriptConsentValue = $cookiesInstance.get(COOKIE_SCRIPT_KEY)
  let cookieIsAllowed: boolean

  if (!cookieScriptConsentValue) {
    cookieIsAllowed = true
  } else if (cookieScriptConsentValue && cookieScriptConsentValue.categories) {
    cookieIsAllowed = cookieScriptConsentValue.categories.includes(category)
  } else {
    cookieIsAllowed = false
  }

  return cookieIsAllowed
}

import Cookies, { CookieAttributes } from 'js-cookie'
import { USER_INFO } from '@/constants/auth'
import { NO_SOURCE_MODAL_KEY, TRIAL_EXPIRED_MODAL_KEY } from '@/constants/app'

export default defineNuxtPlugin({
  name: 'cookies',
  setup() {
    const expires = 365

    return {
      provide: {
        cookies: {
          get(key: string): string {
            const value = Cookies.get(key)
            if (value) {
              try {
                return JSON.parse(value)
              } catch (e) {
                return value
              }
            }
          },

          set(
            key: string,
            content: string | object,
            options: CookieAttributes = {},
          ) {
            Cookies.set(key, JSON.stringify(content), { expires, ...options })
          },

          remove(key: string) {
            Cookies.remove(key)
          },

          removeCookies() {
            const cookies = Cookies.get()
            Object.keys(cookies).forEach((key) => {
              Cookies.remove(key)
            })
          },

          setTrialExpiredCookie() {
            this.set(TRIAL_EXPIRED_MODAL_KEY, true)
          },

          trialExpiredCookieExists(): Boolean {
            return this.get(TRIAL_EXPIRED_MODAL_KEY) !== undefined
          },

          setNoSourceSubscriptionCookie() {
            this.set(NO_SOURCE_MODAL_KEY, true)
          },

          noSourceSubscriptionCookieExists(): Boolean {
            return this.get(NO_SOURCE_MODAL_KEY) !== undefined
          },

          getUserInfo() {
            return this.get(USER_INFO)
          },
        },
      },
    }
  },
})

<script setup lang="ts">
import LearnMoreProductsForm from '~/components/forms/contact/LearnMoreProductsForm.vue'
import RequestTrialForm from '~/components/forms/contact/RequestTrialForm.vue'

defineProps({
  isModal: {
    type: Boolean,
    default: false,
  },
  isRequestATrial: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['loaded', 'showMessageSuccess', 'close'])

const close = () => {
  emit('close')
}

onMounted(() => {
  emit('loaded')
})
</script>

<template>
  <div
    :class="[
      'flex',
      {
        'gap-10 grow-0 md:max-w-[800px]': isModal,
        'md:fill-max-width grow md:max-w-[400px]': !isModal,
      },
    ]"
  >
    <section v-if="isModal" class="fill-max-width mb-6 max-w-[400px]">
      <template v-if="isRequestATrial">
        <section>
          <SubscriptionFeatures />
          <section class="mt-5 md:mt-10">
            <VimeoPlayer video-id="770772934" title="Request a trial" />
          </section>
        </section>
      </template>
      <template v-else>
        <h2 class="text-3xl font-bold md:text-4xl">
          Sign up for the Benchmark Source free weekly newsletter
        </h2>

        <NewsletterSubscriptionFeatures class="text-sm md:text-base" />
      </template>
    </section>

    <section>
      <p v-if="!isModal" class="mb-4 font-semibold w-full">
        Contact us to subscribe or request a free trial
      </p>

      <LearnMoreProductsForm
        v-if="!isRequestATrial"
        class="h-full"
        @close="close"
      />
      <RequestTrialForm v-else class="h-full" @close="close" />
    </section>
  </div>
</template>

<template>
  <div
    :class="[
      twMerge('flex h-full flex-col items-center justify-center', attrs.class),
    ]"
  >
    <loadingSpinner :class="[twMerge('text-p-500 !py-0', sizeClasses)]" />
  </div>
</template>

<script setup lang="ts">
/** packages */
import { twMerge } from 'tailwind-merge'

/** types */
import type { TGeneral, TLoaderSize } from '@/types/app.type'

/** props */
const props = withDefaults(
  defineProps<{
    size?: TLoaderSize
  }>(),
  {
    size: 'large',
  },
)

/** app */
const attrs = useAttrs() as TGeneral

const sizes = {
  small: '[&>*]:size-7',
  large: '[&>*]:size-16',
  xlarge: '[&>*]:size-24',
}

/** computed */
const sizeClasses = computed(() => sizes[props.size])
</script>

import { useMembershipStore } from '@/stores/membership'

export function useShowRequestTrial() {
  const membershipStore = useMembershipStore()
  const { $modal } = useNuxtApp()

  const isRequestTrialButtonActive = computed(() => {
    return (
      !$modal.isRequestATrialActive() &&
      !(
        membershipStore.hasOnlyTrialActive || membershipStore.isSubscriberActive
      ) &&
      membershipStore.hasLoaded
    )
  })

  return {
    isRequestTrialButtonActive,
  }
}

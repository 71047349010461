<script setup lang="ts">
import { PropType } from 'vue'
import { useAppStore } from '~/stores/app'

const props = defineProps({
  variant: {
    type: String as PropType<
      'primary' | 'secondary' | 'tertiary' | 'text' | 'unstyled'
    >,
    default: 'primary',
  },
  href: {
    type: String,
    required: true,
  },
  customNavigation: {
    type: Function,
    default: null,
  },
  as: {
    type: String,
    default: 'a',
  },
})

const app = useAppStore()

function navigate() {
  if (props.customNavigation) {
    props.customNavigation()
  } else if (app.isDifferentAppVersion) {
    window.location.assign(props.href)
  } else {
    useRouter().push(props.href)
  }
}

const isUnstyled = computed(() => props.variant === 'unstyled')
</script>

<!--
  We want this link's tag element to be customizable,
  in case we want to put a clickable element inside
  another one. Nesting anchors inside each other, or
  buttons inside buttons is invalid HTML, and
  therefore it causes hydration mismatch errors.
-->
<template>
  <component
    :is="as"
    class="cursor-pointer"
    :class="
      isUnstyled
        ? ''
        : `btn ${variant}-btn flex items-center justify-center gap-x-3 rounded px-4 py-2 text-xs font-semibold text-s-900`
    "
    :href="href"
    role="link"
    @keypress.enter="navigate"
    @click.stop.prevent="navigate"
  >
    <slot />
  </component>
</template>

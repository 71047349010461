<script setup lang="ts">
defineProps({
  isModal: {
    type: Boolean,
    default: false,
  },
  isRequestATrial: {
    type: Boolean,
    default: false,
  },
  defaultValues: {
    type: Object,
    default: () => ({}),
  },
})

const $config = useRuntimeConfig()
const message = ref(
  'Thank you for contacting us, one of our team will be in touch shortly.',
)
const emit = defineEmits(['loaded', 'showMessageSuccess'])

const handleEvent = () => {
  emit('showMessageSuccess')
}
</script>

<template>
  <div
    :class="
      isModal ? 'gap-10 md:flex' : 'md:fill-max-width grow md:max-w-[400px]'
    "
  >
    <section v-if="isModal" class="fill-max-width mb-6 max-w-[400px]">
      <template v-if="isRequestATrial">
        <section>
          <SubscriptionFeatures />
          <section class="mt-5 md:mt-10">
            <VimeoPlayer video-id="770772934" title="Request a trial" />
          </section>
        </section>
      </template>
      <template v-else>
        <h2 class="text-3xl font-bold md:text-4xl">
          Sign up for the Benchmark Source free weekly newsletter
        </h2>

        <NewsletterSubscriptionFeatures class="text-sm md:text-base" />
      </template>
    </section>

    <section class="shrink-0">
      <p v-if="!isModal" class="mb-4 font-semibold">
        Contact us to subscribe or request a free trial
      </p>

      <FormBuilder
        :form-id="$config.public.formidableForms.newsletter"
        field-extra-classes="mb-1"
        :default-values="defaultValues"
        :success-message="message"
        full-width-to-button
        @done="emit('loaded')"
        @form-send-success="handleEvent"
      />
    </section>
  </div>
</template>

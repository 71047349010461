<script setup lang="ts">
import { navigation } from '@/constants/navigation'

const { isRequestTrialButtonActive } = useShowRequestTrial()

const setBodyClasses = (isVisible) => {
  if (typeof document === 'undefined') return

  if (isVisible) {
    document.body.classList.add('overflow-hidden')
    document.body.classList.add('lg:overflow-visible')
  } else {
    document.body.classList.remove('overflow-hidden')
  }
}

useMobileMenuVisibilityWatcher(setBodyClasses)

const router = useRouter()

const navigationMobile = [...navigation, { path: '/about', label: 'About' }]

router.afterEach(() => {
  setBodyClasses(false)
})

onMounted(() => {
  setBodyClasses(false)
})

const conditionalPadding = computed(() => {
  if (!isRequestTrialButtonActive.value) return

  return 'pt-11'
})
</script>

<template>
  <transition
    mode="in-out"
    enter-from-class="opacity-0"
    enter-active-class="transition-opacity duration-300"
    leave-active-class="transition-opacity duration-500"
    leave-to-class="opacity-0"
  >
    <div
      v-show="$modal.mobileMenu"
      class="fixed z-[9999] flex h-screen w-full flex-col bg-[#EFEFEF] md:mt-9"
    >
      <MobileHeader class="block lg:hidden" />

      <hr class="text-s-300" />

      <ButtonRequestATrial class="bottom-auto top-11" />

      <nav
        data-testid="mobile-navigation"
        class="w-full overflow-y-scroll pt-7 md:pt-7"
        :class="conditionalPadding"
      >
        <MobileNavigationLink
          v-for="link in navigationMobile"
          :key="link.path"
          :level-one-link="link"
          :class="link.variant === 'secondary' && 'bg-p-50'"
        />
      </nav>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core'
import IconCloseCircle from '@/assets/icons/close-circle.svg'
import IconLoading from '@/assets/icons/loading.svg'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  async: {
    type: Boolean,
    default: false,
  },
})

const { $modal } = useNuxtApp()

const isOpen = computed(() => $modal[props.name])
const loading = ref(props.async)

const imageSrc = computed(() => $modal.getImageSrc)

const close = () => {
  $modal.close(props.name)
  loading.value = props.async
}

onKeyStroke('Escape', close)
</script>

<template>
  <transition
    enter-from-class="opacity-0"
    enter-active-class="transition-opacity duration-200"
    leave-active-class="transition-opacity duration-500"
    leave-to-class="opacity-0"
  >
    <div
      v-if="isOpen && imageSrc"
      class="fixed left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-s-900 bg-opacity-70"
      @click.self="close"
    >
      <IconLoading v-show="loading" class="my-28 h-7 w-7 text-p-500" />

      <div
        v-show="!loading"
        class="relative mx-2.5 flex max-h-[calc(100vh_-_2rem)] px-2 py-12"
        @click.self="close"
      >
        <div class="overflow-hidden px-4 md:px-10" @click.self="close">
          <button
            :data-testid="`close-${name}-modal-btn`"
            class="absolute right-5 top-5 z-40"
            @click.prevent="$modal.close(name)"
          >
            <IconCloseCircle />
          </button>
          <div class="h-full w-full" @click.self="close">
            <img
              width="600"
              height="500"
              :src="imageSrc"
              alt=""
              class="mx-auto h-auto max-h-[calc(100vh_-_6rem)] w-full object-cover md:h-full md:w-auto"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

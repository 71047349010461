<script setup lang="ts">
import HSForm from '~/components/forms/HSForm.vue'

const config = useRuntimeConfig()
const formId = config.public.hubspotRequestATrialFormId

defineEmits(['close'])
</script>

<template>
  <HSForm class="h-full" :form-id="formId" @close="$emit('close')">
    <template #header>
      <slot name="header" />
      <h5 class="text-lg font-semibold mb-3">Request a trial</h5>
      <p class="ml-auto w-fit text-xxs">
        Fields marked <span class="text-error">*</span> are required
      </p>
    </template>
  </HSForm>
</template>

<style>
.bm-custom-form .hs-submit {
  margin-top: auto;
}
</style>

import { format } from 'date-fns'
import { defineStore } from 'pinia'
import { type MembershipStatus } from '@/models/membership'

interface State {
  membershipStatuses: MembershipStatus[]
  loading: boolean
  trial: MembershipStatus | undefined
  subscriber: MembershipStatus | undefined
  showTrialEndedModal: boolean
}

const CANCELLATION_STATUSES = ['expired', 'cancelled']
const checkIfSubscriptionExpires = (
  membership: MembershipStatus | undefined,
) => {
  if (!membership?.status) return true

  return CANCELLATION_STATUSES.includes(membership?.status)
}

const shouldShowTheTrialEndedModal = (state) =>
  state.trial && checkIfSubscriptionExpires(state.trial) && !state.subscriber

const defaultState = {
  membershipStatuses: [],
  trial: undefined,
  subscriber: undefined,
  loading: true,
  showTrialEndedModal: false,
}

export const useMembershipStore = defineStore('membership-store', {
  state: () => {
    return <State>{ ...defaultState }
  },

  actions: {
    setMembershipStatuses(memberships: MembershipStatus[]) {
      this.membershipStatuses = memberships

      this.trial = memberships.find((membership) => membership.slug === 'trial')
      this.subscriber = memberships.find(
        (membership) => membership.slug === 'subscriber',
      )
      this.showTrialEndedModal = shouldShowTheTrialEndedModal(this)
      this.loading = false
    },

    resetInfo() {
      Object.keys(defaultState).forEach((key) => {
        this[key] = defaultState[key]
      })
    },
  },

  getters: {
    isSubscriberActive: (state) =>
      !checkIfSubscriptionExpires(state.subscriber),
    hasLoaded: (state) => state.loading === false,
    hasTrialExpired: (state) => checkIfSubscriptionExpires(state.trial),
    hasOnlyTrialActive: (state) =>
      state.trial && state.trial.status === 'active' && !state.subscriber,
    expirationDate: (state) => {
      // If there are no subscriptions or all subscriptions have expired,
      // then the user has no subscriptions
      const memberships = state.membershipStatuses.filter(
        (membership) => !checkIfSubscriptionExpires(membership),
      )
      if (!memberships.length) return 'No active subscription'

      // If a subscription with the expiration date is set to 'none',
      // then the user has a subscription that never expires
      const noExpirationSubscriptionExists = memberships.some(
        (membership) => membership.expiration_date === 'none',
      )
      if (noExpirationSubscriptionExists) return 'Subscription never expires'

      const sortedMemberships = memberships.sort((ma, mb) => {
        return (
          new Date(mb.expiration_date).getTime() -
          new Date(ma.expiration_date).getTime()
        )
      })

      // If the user has multiple subscriptions, then the expiration date
      // is the expiration date of the subscription with the latest expiration date
      const label =
        sortedMemberships[0].slug === 'trial'
          ? 'Trial Expiry date'
          : 'Expiry date'

      return `${label}: ${format(
        new Date(sortedMemberships[0].expiration_date),
        'MMMM do, yyyy',
      )}`
    },
  },
})

import { parse, format, subMonths } from 'date-fns'

import { TIME_FORMAT } from '@/constants/app'

const API_FORMAT = 'yyyy-MM-dd'

export function reformatDate(
  date,
  fromFormat = API_FORMAT,
  toFormat = TIME_FORMAT,
) {
  const parsedDate = parse(date, fromFormat, new Date())
  return format(parsedDate, toFormat)
}

export function parseDate(date: string, fromFormat = TIME_FORMAT) {
  return parse(date, fromFormat, new Date())
}

export function defaultDateRanges() {
  const todayUnixTime = new Date().valueOf()

  const lastMonth = subMonths(todayUnixTime, 1)
  const lastQuarter = subMonths(todayUnixTime, 3)
  const lastYear = subMonths(todayUnixTime, 12)

  return {
    'Last month': {
      start: lastMonth.valueOf(),
      end: todayUnixTime,
      startApiFormatted: format(lastMonth, API_FORMAT),
      endApiFormatted: format(todayUnixTime, API_FORMAT),
    },
    'Last 3 months': {
      start: lastQuarter.valueOf(),
      end: todayUnixTime,
      startApiFormatted: format(lastQuarter, API_FORMAT),
      endApiFormatted: format(todayUnixTime, API_FORMAT),
    },
    'Last year': {
      start: lastYear.valueOf(),
      end: todayUnixTime,
      startApiFormatted: format(lastYear, API_FORMAT),
      endApiFormatted: format(todayUnixTime, API_FORMAT),
    },
    'Custom date': {
      start: 0,
      end: todayUnixTime,
      startApiFormatted: format(0, API_FORMAT),
      endApiFormatted: format(todayUnixTime, API_FORMAT),
    },
  }
}

export function defaultPresentationsDateRanges() {
  const todayUnixTime = new Date().valueOf()

  const lastQuarter = subMonths(todayUnixTime, 3)
  const lastYear = subMonths(todayUnixTime, 12)
  const customDate = parseDate(reformatDate('2016-01-01'))

  return {
    'Last 3 months': {
      start: lastQuarter.valueOf(),
      end: todayUnixTime,
      startApiFormatted: format(lastQuarter, API_FORMAT),
      endApiFormatted: format(todayUnixTime, API_FORMAT),
    },
    'Last year': {
      start: lastYear.valueOf(),
      end: todayUnixTime,
      startApiFormatted: format(lastYear, API_FORMAT),
      endApiFormatted: format(todayUnixTime, API_FORMAT),
    },
    'Custom date': {
      start: customDate.valueOf(),
      end: todayUnixTime,
      startApiFormatted: format(customDate, API_FORMAT),
      endApiFormatted: format(todayUnixTime, API_FORMAT),
    },
  }
}

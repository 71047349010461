import { defineStore } from 'pinia'

interface AppStore {
  appVersion?: string
  remoteAppVersion?: string
}

export const useAppStore = defineStore('app-store', {
  state: (): AppStore => ({
    appVersion: null,
    remoteAppVersion: null,
  }),

  actions: {
    setCurrentVersion(appVersion) {
      this.appVersion = appVersion
      this.remoteAppVersion = appVersion
    },
    setRemoteVersion(remoteAppVersion) {
      this.remoteAppVersion = remoteAppVersion
    },
  },
  getters: {
    isDifferentAppVersion: (state) =>
      state.appVersion !== state.remoteAppVersion,
  },
})

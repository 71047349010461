<script setup lang="ts">
import { useRuntimeConfig } from '#app'
import debounce from 'lodash/debounce.js'
import gsap from 'gsap'
import IconBenchmarkLogo from '@/assets/icons/benchmark-logo-yellow.svg'
import IconAngleDown from '@/assets/icons/angle-down.svg'

import { getAdaptedTickerIndexes } from '@/utils/adapters'
import { logError } from '@/utils/log-error'
import { onTokenChange } from '@/composables/hooks'
import { useIndexesStore } from '@/stores/indexes'
import ClientDebounce from '@/utils/client-debounce'

const debounceStartAnimation = new ClientDebounce().debounce
const debounceResumeAnimation = new ClientDebounce()
const { $restClient } = useNuxtApp()
const indexesStore = useIndexesStore()
const $config = useRuntimeConfig()

const indexesContainer = ref(null)
const loading = ref(true)
const isFirstIndexesContainerMounted = ref(false)
const isSecondIndexesContainerMounted = ref(false)

watch(
  () => ({
    isFirstIndexesContainerMounted: isFirstIndexesContainerMounted.value,
    isSecondIndexesContainerMounted: isSecondIndexesContainerMounted.value,
  }),
  (newValue) => {
    if (
      newValue.isFirstIndexesContainerMounted &&
      newValue.isSecondIndexesContainerMounted
    ) {
      debounceStartAnimation(startAnimation, 1000)
    }
  },
)

let animation = null
const INTERVAL = 0.1
const INTERVAL_DURATION = 0.3

function startAnimation() {
  const speed = 0.03
  const INDEXES_CONTAINER_CLASS = '.indexes-container'
  const indexesContainer = document.querySelectorAll(INDEXES_CONTAINER_CLASS)
  const { scrollWidth } = indexesContainer[0]
  const totalWidth = scrollWidth

  const duration = Math.abs(scrollWidth * speed)

  gsap.set(INDEXES_CONTAINER_CLASS, {
    x: (i) => i * scrollWidth,
  })

  animation = gsap.to(INDEXES_CONTAINER_CLASS, {
    delay: 2,
    duration,
    ease: 'none',
    x: `-=${totalWidth}`,
    modifiers: {
      x: gsap.utils.unitize((x) => parseFloat(x) % totalWidth),
    },
    repeat: -1,
  })

  loading.value = false
}

function moveToLeft() {
  pauseAnimation()
  gsap.to(animation, {
    duration: INTERVAL_DURATION,
    progress: `+=${INTERVAL}`,
  })
  resumeAnimationDelay()
}

function moveToRight() {
  pauseAnimation()
  const currentProgress = animation?.progress()

  if (currentProgress - INTERVAL < 0) {
    /* this code is to avoid a weird animation
     * basically when the user click on the left arrow faster
     * This animation works by dividing the duration
     * according to the progress or position until it reaches 0.
     *
     * E.g. it takes 3s to animate 300px.
     * So, to animate 200px we need to calculate that duration
     * by dividing that width by the total duration of the animation
     * in this example, 3 seconds.
     */
    const durationToZero = currentProgress / INTERVAL_DURATION
    const remainingDuration = INTERVAL_DURATION - durationToZero
    const remainingProgress = INTERVAL - currentProgress

    gsap
      .timeline()
      .to(animation, {
        duration: durationToZero,
        progress: '0',
      })
      .to(animation, {
        duration: 0,
        progress: '1',
      })
      .to(animation, {
        duration: remainingDuration,
        progress: `${1 - remainingProgress}`,
      })
  } else {
    gsap.to(animation, {
      duration: INTERVAL_DURATION,
      progress: `-=${INTERVAL}`,
    })
  }
  resumeAnimationDelay()
}

function pauseAnimation() {
  animation?.pause()
}

function resumeAnimation() {
  animation?.resume()
}
const resumeAnimationDelay = debounce(resumeAnimation, 1000)

async function getTickerIndexes() {
  try {
    indexesStore.setIndexesStates(
      getAdaptedTickerIndexes(await $restClient.getTickerIndexes()),
    )
  } catch (e) {
    logError(e)
  }
}

onTokenChange(getTickerIndexes)
</script>

<template>
  <div class="bg-s-900 text-xs text-white">
    <div
      class="relative mx-auto flex h-9 max-w-content-container items-center justify-between gap-2 px-5 py-2"
    >
      <!-- Left -->
      <div
        class="relative flex min-w-0 grow transition-opacity"
        :class="loading ? 'opacity-0' : 'opacity-100'"
      >
        <button @click="moveToRight">
          <IconAngleDown class="rotate-90 fill-white" />
        </button>
        <div
          id="ticker-container"
          ref="indexesContainer"
          class="relative flex grow overflow-hidden scroll-smooth pr-16 font-semibold"
          @mouseover="
            () => {
              pauseAnimation()
              debounceResumeAnimation.clear()
            }
          "
          @mouseleave="debounceResumeAnimation.debounce(resumeAnimation, 1000)"
        >
          <div class="indexes-container absolute flex gap-8 pr-8">
            <TickerIndex
              v-for="index in indexesStore.$state.indexesStates"
              :key="index.name"
              :name="index.name"
              :today="index.today"
              :percentage-change="index.percentageChange"
              :published-date="index.publishedDate"
              :update-frequency-text="index.updateFrequencyText"
              :has-access="index.hasAccess"
              @vue:mounted="isFirstIndexesContainerMounted = true"
            />
          </div>
          <div class="indexes-container absolute flex gap-8 pr-8">
            <TickerIndex
              v-for="index in indexesStore.$state.indexesStates"
              :key="index.name"
              :name="index.name"
              :today="index.today"
              :percentage-change="index.percentageChange"
              :published-date="index.publishedDate"
              :update-frequency-text="index.updateFrequencyText"
              :has-access="index.hasAccess"
              @vue:mounted="isSecondIndexesContainerMounted = true"
            />
          </div>
        </div>
        <button @click="moveToLeft">
          <IconAngleDown class="-rotate-90 fill-white" />
        </button>
      </div>

      <!-- Right -->
      <div class="my-px flex items-center">
        <IconBenchmarkLogo />
        <a
          :href="$config.public.links.website"
          class="ml-1.5 text-xs font-normal"
        >
          benchmarkminerals.com
        </a>
      </div>
    </div>
  </div>
</template>

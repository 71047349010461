import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import type { UserInfo } from '@/models/auth'
import type { THubSpotUser } from '~/models/hubspot'

export const useAuthStore = defineStore('auth-store', {
  state: () => {
    return {
      authenticated: false,
      token: undefined,
      userInfo: null,
      hubSpotUser: null,
    } as {
      authenticated: boolean
      token?: string | boolean
      userInfo: UserInfo
      hubSpotUser: THubSpotUser | null
    }
  },

  actions: {
    setToken(token: string | boolean) {
      this.token = token
    },

    setUserInfo(info: UserInfo) {
      Sentry.setUser({ id: info.id, email: info.email, username: info.name })
      this.userInfo = info
    },

    setAuthenticated(authenticated: boolean) {
      this.authenticated = authenticated
    },

    setHubSpotUser(value: THubSpotUser) {
      this.hubSpotUser = value
    },

    clear() {
      Sentry.setUser(null)
      this.token = false
      this.userInfo = null
      this.authenticated = false
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.token && state.authenticated,
    canPreviewContent: (state) => !!state.userInfo?.canPreviewContent,
  },
})

import { defineRule, configure } from 'vee-validate'
import {
  required,
  email,
  numeric,
  confirmed,
  between,
  regex,
  min,
} from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json'
import { blackListEmails } from '@/constants/blackListEmails'

export default defineNuxtPlugin(() => {
  defineRule('min', min)
  defineRule('required', required)
  defineRule('email', email)
  defineRule('numeric', numeric)
  defineRule('confirmed', confirmed)
  defineRule('between', between)
  defineRule('password', (value: string) => {
    return (
      required(value) &&
      regex(value, { regex: /[A-Z]/ }) &&
      regex(value, { regex: /[a-z]/ }) &&
      regex(value, { regex: /\d/ }) &&
      value.length >= 8
    )
  })

  defineRule('business-email', (value: string) => {
    if (blackListEmails.some((blacklisted) => value.includes(blacklisted))) {
      return 'Please enter a valid company email'
    }
    return true
  })

  configure({
    validateOnInput: true,
    generateMessage: localize('en', {
      messages: {
        ...en.messages,
        password:
          'At least 8 characters, with one uppercase letter, one lowercase letter, and one number.',
      },
    }),
  })
})


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as accountFCGaMrzILUMeta } from "/opt/buildhome/repo/pages/account.vue?macro=true";
import { default as _91slug_93viTZwr5jFmMeta } from "/opt/buildhome/repo/pages/article/[slug].vue?macro=true";
import { default as _91_91subcategory_93_93donfSPVtjpMeta } from "/opt/buildhome/repo/pages/category/[category]/[[subcategory]].vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as cookiesZLbBnefQgdMeta } from "/opt/buildhome/repo/pages/cookies.vue?macro=true";
import { default as data_45visualisationomVH0Q5g17Meta } from "/opt/buildhome/repo/pages/data-visualisation.vue?macro=true";
import { default as magazineNynhCkzau6Meta } from "/opt/buildhome/repo/pages/downloads/magazine.vue?macro=true";
import { default as _91id_938jjbkXaJ9LMeta } from "/opt/buildhome/repo/pages/downloads/presentations/[id].vue?macro=true";
import { default as indexhaihqjCKH1Meta } from "/opt/buildhome/repo/pages/downloads/presentations/index.vue?macro=true";
import { default as _91id_93cxJT8Q9XyfMeta } from "/opt/buildhome/repo/pages/downloads/special-issues/[id].vue?macro=true";
import { default as indexty4v6AaxBkMeta } from "/opt/buildhome/repo/pages/downloads/special-issues/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as newsletterdypMw9Ct6LMeta } from "/opt/buildhome/repo/pages/newsletter.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as request_45a_45trial1URascGKs5Meta } from "/opt/buildhome/repo/pages/request-a-trial.vue?macro=true";
import { default as reset_45passwordR3tuU8TAkoMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as _91category_93UkmPn3MmMvMeta } from "/opt/buildhome/repo/pages/search/category/[category].vue?macro=true";
import { default as indexQxlpTD49gXMeta } from "/opt/buildhome/repo/pages/search/index.vue?macro=true";
import { default as _91tag_93F9dl5E93SAMeta } from "/opt/buildhome/repo/pages/search/tag/[tag].vue?macro=true";
import { default as set_45password7SadfZikENMeta } from "/opt/buildhome/repo/pages/set-password.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as _91_91category_93_93TBwFjkgGwRMeta } from "/opt/buildhome/repo/pages/video/[[category]].vue?macro=true";
import { default as _91slug_93XLR1nnQSnOMeta } from "/opt/buildhome/repo/pages/video/watch/[slug].vue?macro=true";
import { default as guestijF7q31jkSMeta } from "/opt/buildhome/repo/pages/viewer/pdf/[[id]]/guest.vue?macro=true";
import { default as indexluuu6weJ4jMeta } from "/opt/buildhome/repo/pages/viewer/pdf/[[id]]/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/opt/buildhome/repo/pages/account.vue")
  },
  {
    name: "article-slug",
    path: "/article/:slug()",
    component: () => import("/opt/buildhome/repo/pages/article/[slug].vue")
  },
  {
    name: "category-category-subcategory",
    path: "/category/:category()/:subcategory?",
    component: () => import("/opt/buildhome/repo/pages/category/[category]/[[subcategory]].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "cookies",
    path: "/cookies",
    component: () => import("/opt/buildhome/repo/pages/cookies.vue")
  },
  {
    name: "data-visualisation",
    path: "/data-visualisation",
    component: () => import("/opt/buildhome/repo/pages/data-visualisation.vue")
  },
  {
    name: "downloads-magazine",
    path: "/downloads/magazine",
    component: () => import("/opt/buildhome/repo/pages/downloads/magazine.vue")
  },
  {
    name: "downloads-presentations-id",
    path: "/downloads/presentations/:id()",
    component: () => import("/opt/buildhome/repo/pages/downloads/presentations/[id].vue")
  },
  {
    name: "downloads-presentations",
    path: "/downloads/presentations",
    component: () => import("/opt/buildhome/repo/pages/downloads/presentations/index.vue")
  },
  {
    name: "downloads-special-issues-id",
    path: "/downloads/special-issues/:id()",
    component: () => import("/opt/buildhome/repo/pages/downloads/special-issues/[id].vue")
  },
  {
    name: "downloads-special-issues",
    path: "/downloads/special-issues",
    component: () => import("/opt/buildhome/repo/pages/downloads/special-issues/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/opt/buildhome/repo/pages/newsletter.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/buildhome/repo/pages/privacy.vue")
  },
  {
    name: "request-a-trial",
    path: "/request-a-trial",
    component: () => import("/opt/buildhome/repo/pages/request-a-trial.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue")
  },
  {
    name: "search-category-category",
    path: "/search/category/:category()",
    component: () => import("/opt/buildhome/repo/pages/search/category/[category].vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/opt/buildhome/repo/pages/search/index.vue")
  },
  {
    name: "search-tag-tag",
    path: "/search/tag/:tag()",
    component: () => import("/opt/buildhome/repo/pages/search/tag/[tag].vue")
  },
  {
    name: "set-password",
    path: "/set-password",
    component: () => import("/opt/buildhome/repo/pages/set-password.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/buildhome/repo/pages/terms.vue")
  },
  {
    name: "video-category",
    path: "/video/:category?",
    component: () => import("/opt/buildhome/repo/pages/video/[[category]].vue")
  },
  {
    name: "video-watch-slug",
    path: "/video/watch/:slug()",
    component: () => import("/opt/buildhome/repo/pages/video/watch/[slug].vue")
  },
  {
    name: "viewer-pdf-id-guest",
    path: "/viewer/pdf/:id?/guest",
    meta: guestijF7q31jkSMeta || {},
    component: () => import("/opt/buildhome/repo/pages/viewer/pdf/[[id]]/guest.vue")
  },
  {
    name: "viewer-pdf-id",
    path: "/viewer/pdf/:id?",
    meta: indexluuu6weJ4jMeta || {},
    component: () => import("/opt/buildhome/repo/pages/viewer/pdf/[[id]]/index.vue")
  }
]
import { v4 as uuiv4 } from 'uuid'
import {
  BACKEND_TOKEN_GENERATOR,
  JWT_ACCESS_TOKEN,
  REFRESH_TOKEN,
  USER_INFO,
  BACKEND_VERSION,
} from '@/constants/auth'
import { useAuthStore } from '@/stores/auth'
import { type LoginRequest } from '@/models/auth'
import loginQuery from '@/queries/login.gql'
import { getAdaptedUser } from '@/utils/adapters'
import { useMembershipStore } from '@/stores/membership'
import { TRIAL_EXPIRED_MODAL_KEY } from '~/constants/app'

export default defineNuxtPlugin(
  ({ $apollo, $cookies, $token, $config, $membership, $hubspotIdentity }) => {
    const authStore = useAuthStore()
    const membershipStore = useMembershipStore()

    const login = async (params: LoginRequest) => {
      const response = await $apollo.privateClient.mutate({
        mutation: loginQuery,
        variables: {
          clientMutationId: uuiv4(),
          username: params.username,
          password: params.password,
        },
      })

      const { authToken, refreshToken, user } = response.data.login
      localStorage.setItem(BACKEND_TOKEN_GENERATOR, $config.public.backendUrl)
      const adaptedUser = getAdaptedUser(user)
      $cookies.set(JWT_ACCESS_TOKEN, authToken)
      $cookies.set(REFRESH_TOKEN, refreshToken)
      $cookies.set(USER_INFO, adaptedUser)
      $cookies.set(BACKEND_VERSION, await $token.getBackendVersion())

      authStore.setToken(authToken)
      authStore.setUserInfo(adaptedUser)

      $hubspotIdentity.identifyUser()

      await $membership.loadMembershipStatuses()
      authStore.setAuthenticated(true)
    }

    const logout = () => {
      $cookies.remove(JWT_ACCESS_TOKEN)
      $cookies.remove(REFRESH_TOKEN)
      $cookies.remove(USER_INFO)
      $cookies.remove(BACKEND_VERSION)
      $cookies.remove(TRIAL_EXPIRED_MODAL_KEY)

      membershipStore.resetInfo()
      authStore.clear()
    }

    const restoreAuthState = async () => {
      const token = await $token.getToken()

      authStore.setToken(token || false)
      authStore.userInfo = $cookies.get(USER_INFO)
      authStore.setAuthenticated(!!token)
    }

    return {
      provide: {
        auth: {
          login,
          logout,
          restoreAuthState,
          store: authStore,
          membershipStore,
        },
      },
    }
  },
)
